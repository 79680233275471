/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import HeaderLF from "./headerLF"
import HeaderPDF from "./headerPDF"
import Footer from "./footer"
import NavBar from "./navBar"
import NavBarLF from "./navBarLF"
import TopBar from "./topBar"

import { globalStyles } from '../style/globalStyles';

import { Global } from '@emotion/core'
import CookieConsent, { Cookies } from "react-cookie-consent";
import Helmet from "react-helmet"
 //import "./poj.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  let showPorts =false;
  let showlittleFeet =false;
  let showPDF = false;

  if (typeof window !== 'undefined') {

    if (window.location.href.indexOf("littlefeet") !== -1) {
      showlittleFeet=true; 
    }
    
    if (window.location.href.indexOf("littlefeet") === -1 && window.location.href.indexOf("pdfviewer") === -1) {
      showPorts=true;
    }
    if (window.location.href.indexOf("pdfviewer") !== -1) {
      showPDF=true;
    }

   
  }

  //Littlefeet Environmental
  return (
    <>
     <Helmet>
        <script src="https://pojcdn.blob.core.windows.net/data/Recite.js"> </script>
      </Helmet>
      <Global styles={globalStyles} />
      <div id="s4-workspace">
          <div id="s4-bodyContainer">
            <header>
              {!showPDF && <TopBar />}
              {showPorts && <Header /> }
              {showlittleFeet && <HeaderLF /> }
              {showPDF && <HeaderPDF /> }
              {showPorts && <NavBar /> }
              {showlittleFeet && <NavBarLF /> }
            </header>
            <main>{children}</main>
            {!showPDF && <Footer />} 
        </div>
        </div>
       
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
